.music-container {
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  overflow-y: visible !important;
  overflow-x: hidden !important;
  margin: 0 auto;
  margin-top: var(--nav-height);
  min-height: 60vh;
  margin-bottom: 5rem;
  animation: appearGrow 1s ease-in-out;
}

.music-timeline {
  display: flex;
  align-items: center;
  justify-content: start;
  user-select: none !important;
}

.album {
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: text !important;
}

.image-item {
  margin: 20px 25px;
  margin-top: 50px;
  width: calc(90vw / 3 - 50px) !important;
}
.album h3 {
  margin-top: 10px;
}
.container-with-dots {
  width: 90%;
  margin: 0 auto;

  background-color: rgba(0, 0, 0, 0.487);
}

.album a {
  display: inline-block;
  margin-top: 5px;
  color: #007bff;
  text-decoration: none;
}

.player-container {
  position: relative;
  bottom: 100px;
}

@media (max-width: 1250px) {
  .music-container {
    margin-top: 20px;
    min-height: 65vh;
    justify-content: center;
  }

  .music-container .wrap {
    width: 50vw;
    height: 50vw;
  }

  .stack {
    overflow: visible;
  }

  .container-with-dots {
    padding-bottom: 50px;
  }
  .image-item {
    width: 200% !important;
    margin: 8%;
  }
}

@keyframes appearGrow {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
