.shop-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-top: var(--nav-height);
  min-height: 70vh;
  margin-bottom: 50px;
}

.merch {
  margin-bottom: 5rem;
  z-index: 1000000000;
}

.shop-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 80%;
}

.shop-item {
  border: 2px solid var(--white);
  background-color: rgba(0, 0, 0, 0.464);
  padding: 15px;
  text-align: center;
  color: white;
}

.item-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.item-title {
  margin: 5px 0;
  font-size: 1.2em;
}

.item-description {
  font-size: 0.9em;
}

.item-price {
  font-size: 1.1em;
  color: #979797;
  margin-top: 10px;
}

.shop-item:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;
}

.shop-stack {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 9rem;
  color: var(--dpink);
  text-align: center;
  z-index: 0 !important;
  margin-bottom: 0 !important;
}
