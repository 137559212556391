.bio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 96%;
  margin: 0 auto;
  margin-top: var(--nav-height);
  margin-bottom: 100px;
  min-height: 90vh;
  background-color: rgba(0, 0, 0, 0.555) !important;
  position: relative;
}

.bio-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 5rem;
}

.bio-main span {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin-left: 5rem;
  z-index: 1;
}

.bio-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  z-index: 10;
}

.bio {
  position: relative;
  font-size: 9rem !important;
}
.bio .back {
  color: var(--white) !important;
}

.bio-container p {
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 1.2rem;
  text-align: left;
  margin-top: 2rem;
  opacity: 1;
  overflow: hidden;
  z-index: 0;
}

.bio-container p:last-child {
  margin-bottom: 2rem;
}
