.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  height: 100vh !important;
  width: 100% !important;
  /* background-position: 0% 30%; */
  margin: 0vw auto;
  animation: backgroundFadeIn 1.5s ease-out forwards;
  transition: background-image 1s ease-in-out;
  margin-top: -100px !important;
  overflow: hidden !important;
}

.home-container > img {
  width: 100% !important;
  height: 100% !important;
  z-index: -2;
}

.home-stack {
  position: relative;
  width: 30%;
  color: var(--primary) !important;
  text-align: center;
  z-index: 3 !important;
  overflow: visible !important;
}

.home-stack .bio-back {
  color: #141414 !important;
}

.home-image {
  position: absolute;
  top: 6rem;
  z-index: -2;
  width: 50%;
}

.home-line {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  background-color: #d83b65;
  border-left: 2px solid #d83b65;
}

.home-line-horizontal {
  position: absolute;
  z-index: 1;
  height: 2px;
  width: 100%;
  background-color: #d83b65;
}

.top {
  top: 3rem;
}

.bottom {
  bottom: 3rem;
}

.left {
  left: 2vw;
}

.right {
  right: 2vw;
}

/* Path: src/components/Bio.js */

.music-section {
  background-size: cover;
  transition: background-image 1s ease-in-out;
  margin-top: 10vw;
  margin-bottom: -5vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.music {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5vw;
  position: relative;
  top: -70px;
}

.shop-section {
  min-height: 30vw !important;
  margin-top: 10vw;
  margin-bottom: 5vw;
  background-size: cover;
  transition: background-image 1s ease-in-out;
}

.bio-section {
  margin-top: 3%;
}

.socials {
  position: relative;
  bottom: 3em !important;
  left: 0;
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 1000000000;
}

.social-button {
  font-size: 2em;
  color: var(--white);
  background-color: #1414147e;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid var(--white);
  padding: 1rem;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  box-shadow: 0 0 10px 0px rgb(255, 255, 255);
}

.social-button:hover {
  background-color: var(--white);
  color: var(--dpink);
}

.email-join {
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  color: var(--white);
  border: 2px solid var(--white);
  border-radius: 4px;
  animation: slideInBottom 0.5s ease-out forwards;
}

.email-join::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--white) transparent transparent transparent;
}

@keyframes backgroundFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInBottom {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
