:root {
  --primary: #d83b65;
  --secondary: #000000;
  --text: #f3f3f3;
  --blue: #527af9;
  --black: #141414;
  --white: rgb(255, 255, 255);
  --off-white: #f5f5f5;
  --side-bg: #242424;
  --bg: #121118;
  --lightgrey: #585858;
  --darkgrey: #252525;
  --pink: #d83b65;
  --dpink: #962344;
  --green: #2cd37a;
  --dgreen: #1b7c4f;
  --yellow: #ddc036;
  --red: #e26262;
  --dred: #a23b3b;

  --nav-height: 200px;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; /* Ensure full width */
}

body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: scroll;
  font-family: "Inter", sans-serif;
  background-color: black;
  /* Other styles */
}

.main {
  padding: 0;
  height: 90%;
  width: 100%;
  min-height: 100vh; /* Adjust height */
  overflow-x: hidden !important;
  overflow-y: scroll;
  background-size: cover;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--pink);
  border: none;
  color: var(--text);
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease all;
}

.red {
  background-color: var(--red);
}

.red:hover {
  background-color: var(--dred);
}

.green {
  background-color: var(--green) !important;
}

.green:hover {
  background-color: var(--dgreen) !important;
}

.home.stack {
  color: var(--dpink);
}

.stack {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: -100px;
  margin-top: -60px;
  overflow: hidden;
  flex-direction: row !important;
}

img,
svg text,
.protected-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.stack span {
  font-family: "Dancing Script", cursive;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 9rem;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
}

.stack span:nth-child(odd) {
  --glitch-translate: 8px;
}
.stack span:nth-child(even) {
  --glitch-translate: -8px;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}

.drop-in {
  animation: drop-in 600ms ease 200ms backwards;
}

.drop-in-2 {
  animation: drop-in 200ms ease 200ms backwards;
}

.fade-up {
  animation: fade-up 1s ease 200ms backwards;
}

.fade-down {
  animation: fade-down 1s ease 200ms backwards;
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes fade-down {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

.loading {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: var(--dpink);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Create custom scroll bar */

::-webkit-scrollbar {
  width: 3px;
  position: absolute;
}

/* Track */

::-webkit-scrollbar-track {
  background: black;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*# sourceMappingURL=App.css.map */
.noselect {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-user-drag: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
