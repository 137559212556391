/* AnnouncementBanner.css */

.announcement-banner {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
