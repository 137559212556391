.nav-cart {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.607);
  padding: 1rem;
  border: 2px solid var(--white);
  border-radius: 2px;
  font-size: 1.5em;
  cursor: pointer;
  transition: transform 0.2s ease;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  z-index: 100;
}

.nav-cart:hover {
  color: thistle;
  transition: all;
}

.cart-count {
  position: relative;
  top: -13px;
  font-size: 0.8em;
}

.cart-main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.cart-main h2 {
  width: 70%;
  font: 1.8em sans-serif;
  margin-left: 10px;
  white-space: wrap;
}

.cart-main img {
  width: 20%;
}

.cart-main h3 {
  width: 10%;
  font: 1.3em sans-serif;
  text-align: right;
  color: rgb(155, 155, 155);
}

.cart-footer {
  display: flex;
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
}

.cart-footer h3 {
  width: 100%;
  font: 1.3em sans-serif;
  text-align: right;
}

.cart-button {
  width: 48% !important;
  border-radius: 4px !important;
}
