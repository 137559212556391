@media (max-width: 1250px) {
  .main {
    margin-top: 0px;
    width: 100%;
  }
  .home-container {
    margin-top: 0px;
    height: 100vh !important;
    min-height: 89vh;
    width: 100%;
    margin: 0 auto;
  }
  .home-container > img {
    height: 100% !important;
    object-fit: cover;

    z-index: -2;
  }
  .home-stack {
    top: 0%;
  }
  .stack span {
    font-size: 4rem !important;
  }
  .bio-back {
    color: black !important;
  }

  .nav-links {
    width: 100%;
    left: 0%;
    top: 0% !important;
    height: auto;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: black;
    border-bottom: 2px solid var(--white);
    flex-direction: column;
  }
  .nav-title {
    font-size: 2.5em !important;
    width: 50%;
    height: 100px;
    margin: 0 auto;
  }
  .nav-title > span {
    line-height: 80%;
  }

  .nav-links-bars {
    color: var(--white);
    background-color: black;
    padding: 3%;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    position: absolute;
    left: 2%;
    top: 2%;
    z-index: 100;
  }

  .nav-cart {
    border: none;
    right: 20px;
  }
  .nav-button {
    font-size: 0.8rem;
    padding: 0rem !important;
  }

  .bio-section {
    margin-top: 0px;
  }
  .bio-main {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .bio-main img {
    width: 100% !important;
  }
  .bio-main span {
    width: 100%;
    font-size: 3em !important;
    margin-left: 5%;
    margin-top: -10%;
  }
  .bio-stack span {
    font-size: 3rem !important;
  }

  .bio-container {
    margin-top: 10%;
    height: fit-content;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .bio-container p {
    font-size: 1rem;
  }

  .music-container .wrap {
    width: 50vw;
    height: 50vw;
  }

  .stack {
    overflow: visible;
  }

  .shop-container {
    margin-top: 10px;
  }

  .shop-stack span {
    font-size: 2.8rem !important;
  }

  .shop-main {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 10px 10px 0px 0px;
  }
  .shop-item {
    font-size: 1em !important;
    padding: 3px;
    width: 100% !important;
  }
  .shop-item img {
    width: 100%;
  }
  .shop-item h3 {
    font-size: 1rem;
  }
  .shop-item p {
    font-size: 1rem;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-links a {
    color: var(--white);
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: 800;
    font-family: "Inter", sans-serif;
  }

  .socials {
    gap: 10px;
    bottom: 1em;
  }
  .social-button {
    font-size: 1.5em;
    padding: 0.9rem;
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 750px) {
  .home-container img {
    object-position: 15% 0%;
  }
}

@keyframes slideDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
